<template>
  <v-dialog v-model="patientDialog" scrollable persistent max-width="1000">
    <v-card>
      <v-card-title>
        Schedule an Appointment for
        <span style="margin-left: 5px; color: rgb(51, 1, 87)">
          {{ patientDetail.name }} {{ patientDetail.first_name }}
          {{ patientDetail.last_name }}
        </span>
        <!-- {{ patientDetail }} -->
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col :sm="carSize" cols="12">
            <v-card :loading="loadevent" flat>
              <v-date-picker
                v-model="today"
                :allowed-dates="getAllowedDates"
                :events="arrayEvents"
                :min="isNow"
                event-color="#2196f3"
                full-width
                style="margin-top: 7px"
                @click:date="pickDate"
              ></v-date-picker>
            </v-card>
            <v-card outlined style="margin: 7px 0" v-show="showMyDetails">
              <v-card-text>
                <div class="mylist">
                  <ul>
                    <li>
                      <div class="myleft">Consulting Type</div>
                      <div class="myright">
                        {{ scheduleDetail.meeting_typesid | capitalize }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">Location</div>
                      <div class="myright">
                        {{ scheduleDetail.meeting_location | capitalize }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                    <li>
                      <div class="myleft">Cost</div>
                      <div class="myright">
                        {{ currency(scheduleDetail.pricing) }}
                      </div>
                      <div class="clearall"></div>
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-show="detail" cols="12" sm="6">
            <v-card
              outlined
              class="scrollable"
              style="height: 505px; margin-top: 7px"
              :loading="loading"
              flat
              loader-height="1"
            >
              <v-card-text style="padding: 0px">
                <v-list two-line>
                  <v-list-item
                    v-for="i in scheduleList"
                    :key="i.id"
                    @click="selectedDate(i)"
                    :disabled="i.accepted ? true : false"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ i.schedule_time_end }} - {{ i.schedule_time_start }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ i.accepted ? "Booked" : "Available" }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon :color="i.accepted ? 'red' : 'blue'">
                        {{ i.accepted ? "mdi-close" : "mdi-check" }}
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <br />
        <div v-show="bookingPane">
          <v-divider></v-divider>
          <br />
          <v-card flat>
            <v-card-text style="padding: 0">
              <v-toolbar dense flat outlined>
                <v-toolbar-title>Made for</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items style="padding-top: 12px" class="capitalize">
                  <b>
                    {{ myPatient.first_name }}
                    {{ myPatient.last_name }}
                  </b>
                </v-toolbar-items>
              </v-toolbar>
              <v-card outlined style="margin: 5px 0px">
                <v-card-text>
                  <div class="datalist">
                    <ul>
                      <li>
                        <div class="myleft">scheduled date</div>
                        <div class="myright">
                          {{ selectedSchedule.schedule_date | myDate }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">Time</div>
                        <div class="myright">
                          {{ selectedSchedule.schedule_time_start }}
                          <span
                            style="padding: 0px 5px; text-transform: lowercase"
                          >
                            to
                          </span>
                          {{ selectedSchedule.schedule_time_end }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">location</div>
                        <div class="myright">
                          {{ selectedSchedule.meeting_location }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">Available</div>
                        <div class="myright">
                          {{ selectedSchedule.accept ? "No" : "Yes" }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">Cost</div>
                        <div class="myright">
                          {{ currency(selectedSchedule.pricing) }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                    </ul>
                  </div>
                </v-card-text>
              </v-card>

              <v-textarea
                counter="240"
                maxLength="240"
                label="initial complaints or comments"
                outlined
                rows="3"
                style="margin-top: 16px"
                v-model="create.initialComplaints"
              ></v-textarea>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              :disabled="$v.create.$invalid"
              v-on="on"
              @click="submit"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
      <!-- {{ myPatient }} -->
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
// import Utils from "../../../mixins/utils.js";
import Utils from "@/mixins/utils.js";
// import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    patientDialog: Boolean,
    myId: { type: Object },
    id: { type: Number },
  },
  mixins: [Utils, validationMixin],
  data() {
    return {
      selected: null,
      loading: false,
      bookingPane: false,
      bookingDialog: {},
      patientDetail: {},
      selectedSchedule: {},
      create: { initialComplaints: null },
      my_subscribers_id: null,
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "medics.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Request",
          to: { name: "patient.request" },
        },
        {
          exact: true,
          link: true,
          text: "Request det",
          to: { name: "patient.request.date" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      outlined: false,
      showMyDetails: false,
      isNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      arrayEvents: [],
      carSize: 12,
      detail: false,
      scheduleList: [],
      scheduleDetail: {},
      headers: [
        { text: "start", value: "schedule_time_start" },
        { text: "end", value: "schedule_time_end" },
        { text: "available", value: "closed", align: "center" },
      ],
    };
  },
  validations: {
    create: {
      initialComplaints: {
        required,
      },
    },
  },
  computed: {
    myPatient() {
      let me = this.myId;
      return me;
    },
  },
  created() {
    this.availableDays();
    this.patientDetail = this.myId;
    console.log("patientDetail", this.patientDetail);
  },
  methods: {
    getAllowedDates(val) {
      if (this.arrayEvents.indexOf(val) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    availableDays() {
      this.loadevent = true;
      let self = this;
      // console.log("id:", this._decode(this.id));
      Restful.subscribers.available
        .mySchedules()
        .then((response) => {
          console.log(response.data);
          let m = response.data.split(",");
          // console.log("m:", m);
          this.arrayEvents = m;
          this.loadevent = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loadevent = false;
        });
    },
    pickDate(e) {
      console.log("e:", e);
      this.loading = true;
      this.carSize = 6;
      this.detail = true;
      let mydate = Date.parse(e);
      let data = { mydate };
      console.log("mydate", data);
      this.theEvent(data);

      Restful.subscribers.available
        .mySchedulesTimes(data)
        .then((response) => {
          if (response.status === 200) {
            console.log("data: ", response);
            this.disregion = false;
            this.scheduleList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    theEvent(data) {
      console.log("date:", data);
      let self = this;
      Restful.subscribers.available
        .mySchedulesTimesDetail(data)
        .then((response) => {
          if (response.status === 200) {
            console.log("data: ", response);
            this.scheduleDetail = response.data;
            this.showMyDetails = true;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    checkStatus(closed, done) {
      if (closed === true || done === true) {
        return true;
      } else {
        return false;
      }
    },
    selectedDate(item) {
      console.log("item", item);
      this.bookingPane = true;
      this.selectedSchedule = item;
    },
    closeDialog() {
      let payload = { id: this.id, state: false };
      this.scheduleList = [];
      this.$emit("close4Patient", payload);

      this.carSize = 12;
      this.showMyDetails = false;
      this.detail = false;
      this.bookingPane = false;
    },
    submit() {
      let mySubscribersId = this.myPatient.my_subscribers_id;
      let medicsAvailableTimesId = this.selectedSchedule.id;
      let initialComplaints = this.create.initialComplaints;
      let data = { mySubscribersId, medicsAvailableTimesId, initialComplaints };

      this.carSize = 12;
      this.showMyDetails = false;
      this.detail = false;
      this.bookingPane = false;
      this.initialComplaints = null;

      // let payload = { state: false, data: data };
      let payload = { id: this.id, data: data, state: false };
      console.log("data: ", payload);
      this.$emit("submit4Patient", payload);
    },
  },
};
</script>
