<template>
  <v-dialog v-model="myDialog" max-width="700" persistent scrollable>
    <v-card>
      <v-card-title>Search for guardian or relation</v-card-title>

      <v-card-text>
        <v-text-field
          outlined
          style="margin-top: 16px"
          clearable
          label="Type name or mobile number to search"
          append-icon="mdi-magnify"
          @click:append="search"
          v-model="create.search"
        ></v-text-field>
        <v-card outlined style="margin: 0 0px">
          <v-card-text>
            <v-list dense three-line>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  v-for="(item, index) in resultList"
                  :key="index"
                  @click.stop="select(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }} {{ item.first_name }} {{ item.last_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="text-transform: none">
                      {{ item.mobile_phone }}, {{ item.email }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ item.city }}, {{ item.physical_address }}
                    </v-list-item-subtitle>
                    <v-divider
                      style="margin: 4px 0"
                      v-if="index < resultList.length - 1"
                      :key="index"
                    ></v-divider>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <div></div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="blue" small text @click="submit" v-on="on">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils";
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [Utils, validationMixin],
  props: {
    searchDialog: Boolean,
  },
  computed: {
    myDialog() {
      return this.searchDialog;
    },
  },
  data() {
    return {
      selectedItem: null,
      create: {
        search: null,
        selectedClient: {},
      },
      resultList: [],
      seload: false,
    };
  },
  validations: {
    create: {
      initialComplaints: {
        required,
        maxLength: maxLength(240),
      },
    },
  },
  watch: {
    "create.search": function (a) {
      console.log("a", a);
      this.clients(a);
    },
  },
  created() {},
  methods: {
    clients(a) {
      this.seload = true;
      let fullName = a;
      let data = { fullName };
      let self = this;
      Restful.subscribers
        .clients(data)
        .then((response) => {
          console.log(response);
          this.resultList = response.data;
          this.seload = false;
        })
        .catch((error) => {
          console.log(error);
          self.seload = false;
        });
    },
    select(a) {
      this.create.selectedClient = a;
      console.log("aaa", this.create.selectedClient);
    },
    search() {
      console.log("search");
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeSearch", payload);
    },
    submit() {
      let selectedClient = this.create.selectedClient;
      let data = { selectedClient };
      let payload = { state: false, data: data };
      this.$emit("submitSearch", payload);
    },
  },
};
</script>
