<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs :items="crumbs" exact>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" icon @click="goBack">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Go Back</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-toolbar flat dense>
        <v-toolbar-title style="width: 150px"> Clients </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              :disabled="distext"
              flat
              hide-no-data
              hide-details
              solo
              label="Type here to search for client by name or by mobile phone"
              v-model="searching"
              class="my_search_text"
              background-color="rgb(240 249 255)"
              style="width: 90%"
              clearable
              @click.clear="clearSearch"
            ></v-text-field>
          </template>
          <span>Search by typing name or mobile phone number of client</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="disSearch"
              color="primary"
              v-on="on"
              @click="search"
              x-small
              text
            >
              search client
            </v-btn>
          </template>
          <span>Click to search for name</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text x-small color="success" @click="newClient" v-on="on">
              Create new Client
            </v-btn>
          </template>
          <span>Create new Client</span>
        </v-tooltip>
      </v-toolbar>
      <br />
      <v-card flat v-show="showNewClient">
        <v-toolbar flat dense>
          <v-toolbar-title> Create New Client </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.stop="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-select
                :items="mydata.gender"
                item-value="id"
                item-text="name"
                v-model="create.gendersid"
                dense
                label="gender"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="create.salutationId"
                :items="mydata.salute"
                item-value="id"
                item-text="name"
                dense
                label="salutation"
                return-object
              ></v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="create.first_name"
                dense
                label="first name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="create.last_name"
                dense
                label="last name"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card outlined dense style="margin-bottom: 20px">
            <v-row dense>
              <v-col cols="12" sm="6" style="padding: 5px 16px">
                <v-switch
                  style="padding: 0"
                  :label="`Registering as a dependency?: ${create.dependency.toString()}`"
                  v-model="create.dependency"
                >
                  <!---->
                </v-switch>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                style="display: flex; justify-content: end; margin-top: 15px"
              >
                <v-btn
                  small
                  text
                  color="primary"
                  :disabled="create.dependency ? false : true"
                  @click.stop="searchDialog = true"
                >
                  {{ searchPerson }}
                  <v-icon style="margin-left: 10px">
                    mdi-human-male-male-child
                  </v-icon>
                </v-btn>
                <SearchClientele
                  :searchDialog="searchDialog"
                  @closeSearch="closeSearch"
                  @submitSearch="submitSearch"
                />
              </v-col>
            </v-row>
          </v-card>

          <v-select
            v-model="create.relation_typesid"
            :items="relationToList"
            :loading="cload"
            :disabled="create.dependency ? false : true"
            dense
            item-text="name"
            item-value="id"
            label="Relation Type with dependency"
            name="name"
            outlined
          ></v-select>

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                :disabled="create.dependency ? true : false"
                v-model="create.email"
                dense
                label="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="create.mobile_phone"
                :disabled="create.dependency ? true : false"
                dense
                label="mobile phone"
                counter
                maxlength="10"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-select
                :items="mydata.country"
                item-value="id"
                item-text="name"
                v-model="create.countryid"
                dense
                label="country"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="mydata.region"
                item-value="id"
                item-text="name"
                :disabled="disRegion"
                v-model="create.regionsid"
                dense
                label="region"
              ></v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-select
                :items="mydata.district"
                item-value="id"
                item-text="name"
                v-model="create.districtsid"
                dense
                label="district"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="create.city"
                dense
                label="city"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            v-model="create.physical_address"
            dense
            label="physical address"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            :disabled="$v.create.$invalid"
            @click.stop="submit"
            block
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
      <br />
      <v-card flat v-show="showNewClientResult">
        <v-toolbar flat dense>
          <v-toolbar-title>
            {{ entryResponse.first_name }}
            {{ entryResponse.last_name }}
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn text color="primary" icon @click.stop="closeSchedule">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-btn text color="primary" @click.stop="patientDialog = true">
            Schedule an Appointment
          </v-btn>
          <!-- <AfterSavePatient
            :patientDialog="patientDialog"
            :myId="entryResponse"
            @close4Patient="close4Patient"
            @submit4Patient="submit4Patient"
          /> -->
        </v-card-text>
      </v-card>

      <v-card v-show="searchResult.view" flat>
        <v-card-title> Search Results </v-card-title>
        <v-card-text>
          <v-list three-line dense>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, index) in clientList" :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }} {{ item.first_name }}
                    {{ item.last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="text-transform: none">
                    {{ item.mobile_phone }}, {{ item.email }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.city }}, {{ item.physical_address }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    text
                    small
                    color="primary"
                    @click="$set(patientDialog, item.my_subscribers_id, true)"
                  >
                    schedule an appointment
                  </v-btn>
                  <ForPatientDialog
                    v-if="patientDialog[item.my_subscribers_id]"
                    :patientDialog="patientDialog[item.my_subscribers_id]"
                    :myId="item"
                    :id="item.my_subscribers_id"
                    @close4Patient="close4Patient"
                    @submit4Patient="submit4Patient"
                  />
                </v-list-item-action>
              </v-list-item>
              <!-- <v-divider
                style="margin: 4px 0"
                v-if="index < clientList.length - 1"
                :key="index"
              ></v-divider> -->
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </div>

    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import WinScroll from "../../mixins/windowscroll";
import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils";
import { VSpacer } from "vuetify/lib";
import SearchClientele from "../../components/patient/search_client.vue";
import ForPatientDialog from "../../components/appointments/medic/forPatientDialog.vue";
// import AfterSavePatient from "../../components/appointments/medic/forPatient.vue";

export default {
  components: { VSpacer, SearchClientele, ForPatientDialog },
  mixins: [WinScroll("position"), Utils, validationMixin],
  data() {
    return {
      patientDialog: {},
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "medics.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Cients ",
          to: { name: "medics.client" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      items: [
        { text: "Make Quick Appointment", icon: "mdi-arrow-right" },
        { text: "Search for Client", icon: "mdi-arrow-right" },
      ],
      disSearch: false,
      distext: false,
      searchDialog: false,
      cload: false,
      searchPerson: null,
      showNewClientResult: false,
      relationToList: [],
      selectedPerson: {},
      showSearchResult: false,
      create: {
        gendersid: null,
        salutationId: null,
        first_name: null,
        last_name: null,
        dependency: false,
        subscriber_types_id: 5,
        relation_typesid: null,
        email: null,
        mobile_phone: null,
        physical_address: null,
        countryid: null,
        regionsid: null,
        districtsid: null,
        city: null,
      },
      disRegion: true,
      disDistrict: true,
      entryResponse: {
        subscriberId: null,
        userid: null,
        first_name: null,
        last_name: null,
      },
      mydata: {
        gender: [],
        salute: [],
        country: [],
        region: [],
        district: [],
      },
      searching: null,
      selectedItem: null,
      showNewClient: false,
      searchResult: {
        name: 12,
        nameDetails: 0,
        view: false,
        clientDetails: false,
      },
      clientList: [],
    };
  },
  validations: {
    create: {
      gendersid: {
        required,
      },
      salutationId: {
        required,
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      dependency: {
        required,
      },
      physical_address: {
        required,
      },
      countryid: {
        required,
      },
      regionsid: {
        required,
      },
      districtsid: {
        required,
      },
      city: {
        required,
      },
    },
  },
  watch: {
    searching: function (a) {
      console.log("a", a);
      this.searchit(a);
    },
    "create.countryid": function (a) {
      console.log("a", a);
      if (a > 0) {
        this.region(a);
      }
    },
    "create.regionsid": function (a) {
      console.log("a", a);
      if (a > 0) {
        this.district(a);
      }
    },
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
    myHeight: function () {
      let h = this.$vuetify.breakpoint.height - 270;
      console.log("h", h);
      return h;
    },
  },
  created() {
    this.salute();
    this.gender();
    this.country();
    this.relationTo();
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    searchit(a) {
      this.searchResult.view = false;
      this.searchResult.clientDetails = false;
      this.getResult(a);
    },
    getResult(a) {
      let fullName = a;
      let data = { fullName };
      Restful.accounts.search
        .findClient(data)
        .then((response) => {
          console.log(response.data);
          let len = response.data.length;
          if (len === 0) {
            this.searchResult.name = 12;
            this.searchResult.nameDetails = 0;
            this.searchResult.view = false;
            this.searchResult.clientDetails = false;
            this.clientList = [];
          } else {
            console.log("len", len);
            this.searchResult.view = true;
            this.showNewClient = false;
            this.clientList = response.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    search() {
      this.searchResult.view = false;
      this.searchResult.clientDetails = false;
      this.getResult(this.searching);
    },
    salute() {
      Restful.general.salutations
        .list()
        .then((response) => {
          console.log("salute", response.data);
          this.mydata.salute = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    gender() {
      Restful.general.gender
        .list()
        .then((response) => {
          this.mydata.gender = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    country() {
      Restful.general.country
        .list()
        .then((response) => {
          this.mydata.country = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    region(id) {
      Restful.general.regions
        .byCountry(id)
        .then((response) => {
          this.disRegion = false;
          this.mydata.region = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    district(id) {
      Restful.general.district
        .byRegion(id)
        .then((response) => {
          this.disDistrict = false;
          this.mydata.district = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    relationTo() {
      this.cload = true;
      let self = this;
      Restful.general.relationTo
        .list()
        .then((response) => {
          console.log(response);
          this.relationToList = response.data;
          this.cload = false;
        })
        .catch((error) => {
          console.log(error);
          self.cload = false;
        });
    },
    newClient() {
      console.log("create new");
      this.searchResult.view = false;
      this.showNewClient = true;
      this.searchResult.clientDetails = false;
      this.showNewClientResult = false;
      this.disSearch = true;
      this.distext = true;
    },
    close() {
      this.showNewClient = false;
      this.disSearch = false;
      this.distext = false;
    },
    closeSearch(a) {
      this.searchDialog = a.state;
    },
    submitSearch(a) {
      console.log("data:", a.data);
      this.selectedPerson = a.data.selectedClient;
      this.searchPerson =
        this.selectedPerson.name +
        " " +
        this.selectedPerson.first_name +
        " " +
        this.selectedPerson.last_name;
      this.searchDialog = a.state;
    },
    submit() {
      let city = this.create.city;
      let countryid = this.create.countryid;
      let dependency = this.create.dependency;
      let districtsid = this.create.districtsid;
      let email = this.create.email;
      let first_name = this.create.first_name;
      let gendersid = this.create.gendersid;
      let last_name = this.create.last_name;
      let mobile_phone = this.create.mobile_phone;
      let physical_address = this.create.physical_address;
      let regionsid = this.create.regionsid;
      let relation_typesid = this.create.relation_typesid;
      let salutationid = this.create.salutationId.id;
      let subscriber_types_id = this.create.subscriber_types_id;

      let relatedTo = this.selectedPerson.subscribersid;
      let data = {
        city,
        countryid,
        dependency,
        districtsid,
        email,
        first_name,
        gendersid,
        last_name,
        mobile_phone,
        physical_address,
        regionsid,
        relatedTo,
        relation_typesid,
        salutationid,
        subscriber_types_id,
      };
      console.log("create:", data);
      this.savePerson(data);
    },
    savePerson(a) {
      this.snack.bar = true;
      this.snack.text = "Processing ...";
      this.snack.color = "primary";
      let self = this;
      Restful.subscribers
        .patients(a)
        .then((response) => {
          console.log("salute", response.data);
          if (response.status === 200) {
            this.snack.text = response.data.status
              ? "Successfully saved"
              : "Sorry, could not save!";
            this.snack.color = "success";
            this.entryResponse = response.data;

            this.showNewClientResult = true;
            this.showNewClient = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data);
          self.snack.bar = true;
          self.snack.color = "error";
          self.snack.text =
            "Did not save, ensure you have entered all allowed form fields";
        });
    },
    closeSchedule() {
      this.showNewClientResult = false;
      this.distext = false;
      this.entryResponse = {};
    },
    clearSearch() {
      console.log("aaa");
    },
    details(id) {
      console.log(id);
      this.medavId = id;
    },
    menulink() {
      this.$emit("sidebar");
    },
    close4Patient(a) {
      console.log("close4Patient", a);
      this.$set(this.patientDialog, a.id, a.state);
    },
    submit4Patient(a) {
      this.snack.bar = true;
      this.snack.text = "Processing ...";
      // this.snack.color
      console.log("close4Patient", a);
      if (a.data.initialComplaints === null) {
        console.log("cannot save when initial is not made");
        this.snack.color = "error";
        this.snack.text = "Cannot save when request is not complete";
      } else {
        this.snack.bar = true;
        this.snack.text = "Processing ...";
        this.snack.color = "primary";
        let self = this;
        Restful.medics.patient.request
          .medic(a.data)
          .then((response) => {
            console.log("salute", response.data);
            if (response.status === 200) {
              this.snack.text = response.data;
              this.snack.color = "success";
              this.showNewClientResult = true;
              this.showNewClient = false;
            }
          })
          .catch((error) => {
            console.log(error.response);
            console.log(error.response.data);
            self.snack.bar = true;
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      }
      this.patientDialog = a.state;
    },
  },
};
</script>
